import React     from "react";
import PropTypes from "prop-types";

export const ProgramView = props => {
    const element = props.element;
    return (
        <div className={"card shadow-lg programs-card"}>
            <div className="card-body">
                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <h3 className="card-title">
                            <i className="fas fa-graduation-cap me-2 text-primary"></i>{element.shortName}
                            {element.shortName !== element.name?
                                <p className={"text-muted"}>{element.name}</p>
                                :
                                ""
                            }
                        </h3>
                    </div>
                    <div className={"col-3"}>
                        <a className={"btn btn-success btn-sm me-2 mb-2"}
                           role={"button"}
                           href={`/programs/${element.ID}/edit/`}>
                            <i className="fas fa-pencil-alt me-2"></i>
                            Изменить
                        </a>
                        <a className={"btn btn-primary btn-sm mb-2"}
                           role={"button"}
                           href={`/programs/add/`}>
                            <i className="fas fa-plus me-2"></i>
                            Добавить
                        </a>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col mb-3"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-layer-group me-2 text-primary"></i>Направление
                                </h4>
                                {element.department?.name}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-link me-2 text-primary"></i>Ссылка на сайте
                                </h4>
                                {element.link}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                            <div className={"card shadow-sm h-100"}>
                                <div className="card-body text-center">
                                    <h4 className={"card-title"}>
                                        Архивная программа
                                    </h4>
                                    {element.isArchive ?
                                    <i className="fas fa-check text-danger fa-2x"></i>
                                        :
                                        <i className="fas fa-minus text-success fa-2x"></i>
                                    }
                                </div>
                            </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col mb-3"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-wallet me-2 text-primary"></i>Стоимость
                                </h4>
                                {element.costDisplay}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="far fa-clock me-2 text-primary"></i>Часов (всего/месяц)
                                </h4>
                                {element.thours} / {element.mhours}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="far fa-calendar-alt me-2 text-primary"></i>Продолжительность
                                </h4>
                                {element.long}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-end"}>
                        <a className={"btn btn-primary btn-sm"}
                           role={"button"}
                           href={`/programs/`}>
                            Назад к списку программ
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProgramView.propTypes = {
    backUrl: PropTypes.string,
    element: PropTypes.object,
};
