import { Pages }          from "./Pages";
import ReactDOM           from "react-dom";
import React         from "react";
import {ProgramView} from "../components/ProgramView";

export class ProgramsDetail extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <ProgramView
                backUrl={this.data.backUrl}
                element={this.data.element}/>,
            document.getElementById('programDetail')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
